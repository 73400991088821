html,
body,
#root,
.AppShell {
  height: 100vH;
  min-height: 100%;
  /* min-width: 1680px; */
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "FiraCodeRegular";
  src: local("FiraCodeRegular"),
    url("../fonts/FiraCode-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "FiraCodeMedium";
  src: local("FiraCodeMedium"),
    url("../fonts/FiraCode-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "FiraCodeLight";
  src: local("FiraCodeLight"),
    url("../fonts/FiraCode-Light.ttf") format("truetype");
}

@font-face {
  font-family: "FiraCodeBold";
  src: local("FiraCodeBold"),
    url("../fonts/FiraCode-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "FiraCodeSemiBold";
  src: local("FiraCodeSemiBold"),
    url("../fonts/FiraCode-SemiBold.ttf") format("truetype");
}


@font-face {
  font-family: "InterBlack";
  src: local("InterBlack"),
    url("../fonts/Inter-Black.ttf") format("truetype");
}

@font-face {
  font-family: "InterBold";
  src: local("InterBold"),
    url("../fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "InterExtraBold";
  src: local("InterExtraBold"),
    url("../fonts/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "InterLight";
  src: local("InterLight"),
  url('../fonts/Inter-Light.woff2') format('woff2'),
  url("../fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "InterExtraLight";
  src: local("InterExtraLight"),
    url("../fonts/Inter-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "InterMedium";
  src: local("InterMedium"),
    url('../fonts/Inter-Medium.woff2') format('woff2'),
    url("../fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "InterRegular";
  src: local("InterRegular"),
  url('../fonts/Inter-Regular.woff2') format('woff2'),
  url("../fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "InterSemiBold";
  src: local("InterSemiBold"),
    url('../fonts/Inter-SemiBold.woff2') format('woff2'),
    url("../fonts/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "InterThin";
  src: local("InterThin"),
    url("../fonts/Inter-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "KurrentBold";
  src: local("KurrentBold"),
    url("../fonts/ESKlarheitKurrent-Bold.woff") format("woff");
}

@font-face {
  font-family: "KurrentSemiBold";
  src: local("KurrentSemiBold"),
    url("../fonts/ESKlarheitKurrent-Smbd.woff") format("woff");
}






body {
  padding: 0px;
  margin: 0px;
  font-family: "InterRegular", sans-serif;
  font-size: 16px;
  background-color: #F0F4F6;
  color: #314756;
}


h1,
h2 {
  /* normalize gives these a `em` margin, this matches correctly to our designs.
   */
  margin: 0px 0px 8px;
}


h1,
h2, 
h3, 
h4, 
h5, 
h6 {
  font-weight: normal;
}


.react-syntax-highlighter-line-number:first-child {
  padding-top: 30px !important;

}



.syntax-highlighting {

}

.syntax-highlighting .import {
  color: #46deb8
}

.syntax-highlighting .variable {
  color: #8cecf3
}

.syntax-highlighting .constructor {
  color: #46deb8;
  font-weight: bold;
}